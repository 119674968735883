import {getToken} from "./sessionToken";

function authorization() {
    return {
        headers: {
            "Authorization": getToken()
        }
    }
}
function authorizationResponseType() {
    return {
        headers: {
            "Authorization": getToken(),
        },
        responseType:'blob'
    }
}



function authorizationFiles(){
    return {
        headers: {
            "Authorization": getToken(),
            "Content-Type" : "multipart/form-data"
        }
    }
}

export {
    authorization,
    authorizationFiles,
    authorizationResponseType

}
