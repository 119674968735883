import {createApp, onBeforeMount, onMounted, ref} from 'vue';
import App from './App.vue';
import {router} from './router';
//import { Swipe, SwipeItem } from 'vant-green';
import 'vant/es/toast/style';
import {Dialog, Notify, Toast} from 'vant';
import {isLogin, toLogin} from "../public/static/js/general";
import 'vant/lib/index.css';
import axios from "axios";


const app = createApp(App);
app.use(router);
app.use(Toast);
app.use(Notify)
app.use(Dialog);
//app.use(Swipe).use(SwipeItem);

// 设置全局的 beforeMount 钩子
// app.mixin({
//     beforeMount() {
//         isLogin()
//     }
// });
//设置全局的 Axios 拦截器
axios.interceptors.response.use(
    async response => {
        // if (response.config.url === '/api/appMember/updateUserInformation') {
        //     return response;
        // }
        response.config.baseURL
        // 对响应数据进行处理
        const data = response.data
        if (data.code === '10001' && data.success === false) {
            await toLogin()
        } else {
            return response
        }
    },
    error => {
        // 对响应错误进行处理
        return Promise.reject(error);
    }
);

// 在根组件设置全局方法

//isLogin()
// const globalMethod = () => {
//     alert("wozhixingle !!!!!")
//     isLogin()
//     // 在这里执行全局的方法逻辑
// };

// app.config.globalProperties.$globalMethod = globalMethod;
//
//
// // 创建一个 ref，用于保存是否已执行全局方法的状态
// const hasExecutedGlobalMethod = ref(false);
//
// // 在 setup 函数中执行全局方法
// const setup = () => {
//     if (!hasExecutedGlobalMethod.value) {
//         globalMethod();
//         hasExecutedGlobalMethod.value = true;
//     }
//
//     // 可以在 setup 函数中设置其他响应式数据、生命周期钩子等
//
//     return {};
// };
// app.setup = setup;
//isLogin().then(r => {});
app.mount('#app');



