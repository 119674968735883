import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'box',
    },
  },
  {
    name: 'box',
    path: '/',
    component: () => import('./view/box/index.vue'),
    meta: {
      title: '交友盲盒',
    },

  }, {
    name: 'my',
    path: '/my',
    component: () => import('./view/box/my.vue'),
    meta: {
      title: '摆摊赚钱',
    },

  }, {
    name: 'paper',
    path: '/paper',
    component: () => import('./view/box/myPaper.vue'),
    meta: {
      title: '我的纸条',
    },

  },{
    name: 'withdrawal',
    path: '/withdrawal',
    component: () => import('./view/box/withdrawal.vue'),
    meta: {
      title: '提现',
    }

  }, {
    name: 'registerAgent',
    path: '/registerAgent',
    component: () => import('./view/box/registerAgent.vue'),
    meta: {
      title: '注册代理',
    }
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
