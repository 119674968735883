import axios from 'axios';
import {showFailToast} from 'vant';
import {authorization} from "./headers";
import {getToken, setToken} from "./sessionToken";

async function isLogin() {
    //存在token
    const token = getToken();
    if (null != token && token.length > 30) {
        //不作处理
    } else {
        await toLogin();
    }
}

const num = 3;

async function toLogin() {
    const url = window.location.href;
    //https://wwwjymh.cc/?code=061MxT1w3H8nO13HDZ1w3diCEi1MxT1v&state=STATE/
    if (url.includes('code=')) {
        const code = url.split('code=')[1].split('&')[0];
        let id = "";
        if (url.includes('id=')) {
            id = url.split('id=')[1].split('&')[0];
        }
        await axios.post('/api/appMember/simpleLogin', {'code': code, 'parentId': id}).then(async data => {
            await setToken(data.data.data.token);
        }).catch(async error => {
            // // showFailToast('获取登录信息失败!'+ error.message);
            // for (let i = 0; i < num; i++) {
            //   await toLogin();
            // }
            let redirect_uri = url.replace(window.location.search, "");
            hrefUrl(encodeURIComponent(redirect_uri))
        });
    } else {
        let redirect_uri = encodeURIComponent(url);
        hrefUrl(redirect_uri)
    }
}

function hrefUrl(redirect_uri) {
    // 基础跳转
    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf3ea30b00453cbf6&redirect_uri=' + redirect_uri + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect';
}

function checkLogin(data) {
    if (data.success) {
        return true;
    } else {
        if (data.code === '10001') {
            showFailToast('登录失效，正在重新登录!');
            // setTimeout(function () {
            //     const url = encodeURIComponent("http://www.jymh.cc/#/");
            //     window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf3ea30b00453cbf6&redirect_uri=" + url + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
            // },3000)
        } else {
            return false;
        }
    }
}

export {
    isLogin, checkLogin, toLogin
};
